import React from "react";
import { MetalTradingQuery } from "../../prismic/staticQueries/markets/index.js"
import Layout from "../../layout";
import { Subscription, BreadCrumbBanner } from "../../components/common";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import Carousel from "react-multi-carousel";
import CountUp from "react-countup";
import banner_image from "../../images/siteImages/forex/metals-trading.png";
import markets_side_image from "../../images/siteImages/forex/metal-trading-side.png";
import metalicon1 from "../../images/siteImages/forex/MarketIcons/positions.svg";
import metalicon2 from "../../images/siteImages/forex/MarketIcons/riskfree-demo.svg";
import metalicon3 from "../../images/siteImages/forex/MarketIcons/diversification.svg";
import metalicon4 from "../../images/siteImages/forex/MarketIcons/liquidity.svg";
import { PartyMode } from "@material-ui/icons";


const MetalTrading = () => {
  const language = useSelector((state) => state.language);
  const MetalTradingData = MetalTradingQuery(language);
  console.log("language", MetalTradingData);
  const {
    markets_heading,
    markets_content,
    // markets_side_image,
    all_markets_box,
  } = MetalTradingData;

  const all_markets_icon = [
    metalicon1,
    metalicon2,
    metalicon3,
    metalicon4
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (

    <Layout >
      <BreadCrumbBanner banner_image={banner_image} />
      <section className="sectionbg p-top-10 p-bottom-80 faqs">
        <Container className="">
          <Row className="py-5">
            <Col lg={6}>
              <h2 className="pt-5 my-3 text-bold-md text-black">{markets_heading.text}</h2>
              <p className="text-gray my-3">
                {markets_content.text}</p>
            </Col>
            <Col lg={6} className="py-5">
              <img alt="ddd" src={markets_side_image} />
            </Col>
          </Row>
          <Row className="mt-5">
            {all_markets_box.length > 0 && all_markets_box.map((marketmap, marketmapInd) => (
              <Col className="mb-5"
                lg={3}
                md={6} key={"marketmapInd" + marketmapInd}>
                <div className="market-card mb-4 h-100">
                  <div className="d-flex flex-column mb-2 align-items-center">
                    <div className="box-icon mb-4">
                      <img alt="ddd" src={all_markets_icon[marketmapInd]} className="icon-width" style={{ width: "60px" }} />
                    </div>
                    <h4 className="text-center text-bold-md mb-0 mx-3">
                      {marketmap.all_market_heading.text}
                    </h4>
                    <p> {marketmap.all_market_description.text}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(MetalTrading);